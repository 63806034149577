import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Menu',
    moduleName: 'Masters',
    icon: 'fas fa-tachometer-alt',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      // {
      //   path: 'CarAndDriverAllotment',
      //   title: 'Car And Driver Allotment',
      //   moduleName: 'CarAndDriverAllotment',
      //   icon: 'fas fa-tachometer-alt',
      //   class: '',
      //   groupTitle: false,
      //   submenu: [],
      //   isAccess: false
      // },
      {
        path: 'controlPanelDesign',
        title: 'Control Panel',
        moduleName: 'controlPanelDesign',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'controlPanelTemp',
        title: 'Control Panel Temp',
        moduleName: 'controlPanelTemp',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'CarAndDriverAllotmentTemp',
        title: 'Car And Driver Allotment Temp',
        moduleName: 'CarAndDriverAllotmentTemp',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      // {
      //   path: 'reservationList',
      //   title: 'Reservation List',
      //   moduleName: 'reservationList',
      //   icon: 'fas fa-tachometer-alt',
      //   class: '',
      //   groupTitle: false,
      //   submenu: [],
      //isAccess: false
      // },
      {
        path: 'role',
        title: 'Role',
        moduleName: 'role',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'page',
        title: 'Page',
        moduleName: 'page',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'driverInventoryAssociation',
        title: 'Driver Inventory Association',
        moduleName: 'driverInventoryAssociation',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'acrisCode',
        title: 'ACRISS Code',
        moduleName: 'acrisCode',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      // {
      //   path: 'dailyReport',
      //   title: 'Daily Report',
      //   moduleName: 'dailyReport',
      //   icon: 'fas fa-tachometer-alt',
      //   class: '',
      //   groupTitle: false,
      //   submenu: [],
      //isAccess: false
      // },
      {
        path: 'additionalService',
        title: 'Additional Service',
        moduleName: 'additionalService',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'bank',
        title: 'Bank',
        moduleName: 'bank',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'bankBranch',
        title: 'Bank Branch',
        moduleName: 'bankBranch',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'bankChargeConfig',
        title: 'Bank Charge Config',
        moduleName: 'bankChargeConfig',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'card',
        title: 'Card',
        moduleName: 'card',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'cardProcessingCharge',
        title: 'Card Processing Charge',
        moduleName: 'cardProcessingCharge',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'cardType',
        title: 'Card Type',
        moduleName: 'cardType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'city',
        title: 'City',
        moduleName: 'city',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'color',
        title: 'Color',
        moduleName: 'color',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'cityTier',
        title: 'City Tier',
        moduleName: 'cityTier',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'cityGroup',
        title: 'City Group',
        moduleName: 'cityGroup',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'country',
        title: 'Country',
        moduleName: 'country',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'currency',
        title: 'Currency',
        moduleName: 'currency',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'currencyExchangeRate',
        title: 'Currency Exchange Rate',
        moduleName: 'currencyExchangeRate',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'customer',
        title: 'Customer',
        moduleName: 'customer',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'customerAlertMessageType',
        title: 'Customer Alert Message Type',
        moduleName: 'customerAlertMessageType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'customerContract',
        title: 'Customer Contract',
        moduleName: 'customerContract',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'customerGroup',
        title: 'Customer Group',
        moduleName: 'customerGroup',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'customerCategory',
        title: 'Customer Category',
        moduleName: 'customerCategory',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'customerType',
        title: 'Customer Type',
        moduleName: 'customerType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'department',
        title: 'Department',
        moduleName: 'department',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'designation',
        title: 'Designation',
        moduleName: 'designation',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'document',
        title: 'Document',
        moduleName: 'document',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'driver',
        title: 'Driver',
        moduleName: 'driver',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'driverGrade',
        title: 'Driver Grade',
        moduleName: 'driverGrade',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'driverReward',
        title: 'Driver Reward',
        moduleName: 'driverReward',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      {
        path: 'employee',
        title: 'Employee',
        moduleName: 'employee',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      {
        path: 'unlockEmployee',
        title: 'Unlock Employee',
        moduleName: 'unlockEmployee',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'expense',
        title: 'Expense',
        moduleName: 'expense',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'dutyExpense',
        title: 'Duty Expense',
        moduleName: 'dutyExpense',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'fuelType',
        title: 'Fuel Type',
        moduleName: 'fuelType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'geoPointType',
        title: 'Geo Point Type',
        moduleName: 'geoPointType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'gstPercentage',
        title: 'GST Percentage',
        moduleName: 'gstPercentage',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'inventory',
        title: 'Inventory',
        moduleName: 'inventory',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      {
        path: 'invoiceTemplate',
        title: 'Invoice Template',
        moduleName: 'invoiceTemplate',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      // {
      //   path: 'lut',
      //   title: 'LUT',
      //   moduleName: 'lut',
      //   icon: 'fas fa-tachometer-alt',
      //   class: '',
      //   groupTitle: false,
      //   submenu: [],
      //   isAccess: false
      // },
      {
        path: 'locationGroup',
        title: 'Location Group',
        moduleName: 'locationGroup',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
   
      {
        path: 'messageType',
        title: 'Message Type',
        moduleName: 'messageType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
     
      {
        path: 'modeOfPayment',
        title: 'Mode Of Payment',
        moduleName: 'modeOfPayment',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'organizationalEntity',
        title: 'OrganizationalEntity',
        moduleName: 'organizationalEntity',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },   
      
      {
        path: 'supplierRequiredDocument',
        title: 'Supplier Required Document',
        moduleName: 'supplierRequiredDocument',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
    
      {
        path: 'organizationalEntityMessage',
        title: 'Organizational Entity Message',
        moduleName: 'organizationalEntityMessage',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'organizationalEntityStakeHolders',
        title: 'Organizational Entity Stake Holders',
        moduleName: 'organizationalEntityStakeHolders',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'package',
        title: 'Package',
        moduleName: 'package',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'packageType',
        title: 'Package Type',
        moduleName: 'packageType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'paymentCycle',
        title: 'Payment Cycle',
        moduleName: 'paymentCycle',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'paymentNetwork',
        title: 'Payment Network',
        moduleName: 'paymentNetwork',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'password',
        title: 'Password',
        moduleName: 'password',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'qualification',
        title: 'Qualification',
        moduleName: 'qualification',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'reservationGroup',
        title: 'Reservation Group',
        moduleName: 'reservationGroup',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'salutation',
        title: 'Salutation',
        moduleName: 'salutation',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'sac',
        title: 'SAC',
        moduleName: 'sac',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'serviceType',
        title: 'Service Type',
        moduleName: 'serviceType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'spotInCity',
        title: 'Spot In City',
        moduleName: 'spotInCity',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'state',
        title: 'State',
        moduleName: 'state',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'supplier',
        title: 'Supplier',
        moduleName: 'supplier',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'supplierContract',
        title: 'Supplier Contract',
        moduleName: 'supplierContract',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'supplierCustomerFixedForAllPercentage',
        title: 'Supplier Customer Fixed Percentage For All',
        moduleName: 'supplierCustomerFixedForAllPercentage',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'transmissionType',
        title: 'Transmission Type',
        moduleName: 'transmissionType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'tollParkingType',
        title: 'Toll Parking Type',
        moduleName: 'tollParkingType',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'uom',
        title: 'UOM',
        moduleName: 'uom',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },

      {
        path: 'vehicle',
        title: 'Vehicle',
        moduleName: 'vehicle',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'vehicleCategory',
        title: 'Vehicle Category',
        moduleName: 'vehicleCategory',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      {
        path: 'vehicleManufacturer',
        title: 'Vehicle Manufacturer',
        moduleName: 'vehicleManufacturer',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      {
        path: 'reservationSource',
        title: 'Reservation Source',
        moduleName: 'reservationSource',
        icon: 'fas fa-tachometer-alt',
        class: '',
        groupTitle: false,
        submenu: [],
        isAccess: false
      },
      
      // {
      //   path: 'test',
      //   title: 'Test',
      //   moduleName: 'test',
      //   icon: 'fas fa-tachometer-alt',
      //   class: '',
      //   groupTitle: false,
      //   submenu: [],
      //isAccess: false
      // },
      
    ],
    isAccess: true
  },
]
