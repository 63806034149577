import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NoSidebarLayoutComponent } from './layout/app-layout/no-sidebar-layout/no-sidebar-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'welcome',
        loadChildren: () =>
          import('./welcome/welcome.module').then((m) => m.WelcomeModule)
      },

      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },

      {
        path: 'myupload',
        loadChildren: () =>
          import('./myupload/myupload.module').then(
            (m) => m.MyUploadModule
          )
      },

      {
        path: 'cdcLocalFixedDetails',
        loadChildren: () =>
          import('./cdcLocalFixedDetails/cdcLocalFixedDetails.module').then(
            (m) => m.CDCLocalFixedDetailsModule
          )
      },

      {
        path: 'dutyState',
        loadChildren: () =>
          import('./dutyState/dutyState.module').then(
            (m) => m.DutyStateModule
          )
      },
      {
        path: 'customerKAMCity',
        loadChildren: () =>
          import('./customerKAMCity/customerKAMCity.module').then(
            (m) => m.CustomerKAMCityModule
          )
      },

      {
        path: 'invoiceTemplate',
        loadChildren: () =>
          import('./invoiceTemplate/invoiceTemplate.module').then(
            (m) => m.InvoiceTemplateModule
          )
      },
      {
        path: 'cancelReservation',
        loadChildren: () =>
          import('./cancelReservation/cancelReservation.module').then(
            (m) => m.CancelReservationModule
          )
      },

      {
        path: 'locationGroupLocationMapping',
        loadChildren: () =>
          import('./locationGroupLocationMapping/locationGroupLocationMapping.module').then(
            (m) => m.LocationGroupLocationMappingModule
          )
      },

      {
        path: 'customerCreditCharges',
        loadChildren: () =>
          import('./customerCreditCharges/customerCreditCharges.module').then(
            (m) => m.CustomerCreditChargesModule
          )
      },

      {
        path: 'dutySlipLTRStatement',
        loadChildren: () =>
          import('./dutySlipLTRStatement/dutySlipLTRStatement.module').then(
            (m) => m.DutySlipLTRStatementModule
          )
      },
      
      {
        path: 'contractPackageTypeMapping',
        loadChildren: () =>
          import('./contractPackageTypeMapping/contractPackageTypeMapping.module').then(
            (m) => m.ContractPackageTypeMappingModule
          )
      },

      {
        path: 'employeeLocation',
        loadChildren: () =>
          import('./employeeLocation/employeeLocation.module').then(
            (m) => m.EmployeeLocationModule
          )
      },
      
      {
        path: 'garageIn',
        loadChildren: () =>
          import('./garageIn/garageIn.module').then(
            (m) => m.GarageInModule
          )
      },
      { 
        path: 'CustomerAlertMessageDetails',
        loadChildren: () =>
          import('./customerAlertMessageDetails/customerAlertMessageDetails.module').then(
            (m) => m.CustomerAlertMessageDetailsModule
          )
      },

      {
        path: 'customerInvoiceTemplate',
        loadChildren: () =>
          import('./customerInvoiceTemplate/CustomerInvoiceTemplate.module').then(
            (m) => m.CustomerInvoiceTemplateModule
          )
      },
      
 {
  path: 'dispute',
  loadChildren: () =>
    import('./dispute/dispute.module').then(
      (m) => m.DisputeModule
    )
},
{
  path: 'dutySlipImage',
  loadChildren: () =>
    import('./dutySlipImage/dutySlipImage.module').then(
      (m) => m.DutySlipImageModule
    )
},
 {
        path: 'driverRemark',
        loadChildren: () =>
          import('./driverRemark/driverRemark.module').then(
            (m) => m.DriverRemarkModule
          )
      },
      {
        path: 'driverRemarkDetails',
        loadChildren: () =>
          import('./DriverRemarkDetails/DriverRemarkDetails.module').then(
            (m) => m.DriverRemarkDetailsModule
          )
      },
      {
        path: 'nextDayInstruction',
        loadChildren: () =>
          import('./nextDayInstruction/nextDayInstruction.module').then(
            (m) => m.NextDayInstructionModule
          )
          
      },

      {
        path: 'nextDayInstructionDetails',
        loadChildren: () =>
          import('./NextDayInstructionDetails/NextDayInstructionDetails.module').then(
            (m) => m.NextDayInstructionDetailsModule
          )
      },
      {
        path: 'mopDetailsShow',
        loadChildren: () =>
          import('./MOPDetailsShow/mopDetailsShow.module').then(
            (m) => m.MOPDetailsModule
          )
      },
{
  path: 'dutySlipImageDetailsShow',
  loadChildren: () =>
    import('./dutySlipImageDetailsShow/dutySlipImageDetailsShow.module').then(
      (m) => m.DutySlipImageDetailsShowModule
    )
},
{
  path: 'contractPaymentMapping',
  loadChildren: () =>
    import('./contractPaymentMapping/contractPaymentMapping.module').then(
      (m) => m.ContractPaymentMappingModule
    )
},
      {
        path: 'sendSMS',
        loadChildren: () =>
          import('./sendSMS/sendSMS.module').then(
            (m) => m.SendSMSModule
          )
      },

      {
        path: 'allotCarAndDriver',
        loadChildren: () =>
          import('./allotCarAndDriver/allotCarAndDriver.module').then(
            (m) => m.AllotCarAndDriverModule
          )
      },

      {
        path: 'newForm',
        loadChildren: () =>
          import('./newForm/newForm.module').then(
            (m) => m.NewFormModule
          )
      },
      {
        path: 'feedBackAttachment',
        loadChildren: () =>
          import('./feedBackAttachment/feedBackAttachment.module').then(
            (m) => m.FeedBackAttachmentModule
          )
      },
      {
        path: 'feedBack',
        loadChildren: () =>
          import('./feedBack/feedBack.module').then(
            (m) => m.FeedBackModule
          )
      },
      
      {
        path: 'cancelAllotment',
        loadChildren: () =>
          import('./cancelAllotment/cancelAllotment.module').then(
            (m) => m.CancelAllotmentModule
          )
      },

      {
        path: 'searchDriverByLocation',
        loadChildren: () =>
          import('./searchDriverByLocation/searchDriverByLocation.module').then(
            (m) => m.SearchDriverByLocationModule
          )
      },

      {
        path: 'customerAlertMessageType',
        loadChildren: () =>
          import('./customerAlertMessageType/customerAlertMessageType.module').then(
            (m) => m.CustomerAlertMessageTypeModule
          )
      },

      {
        path: 'transmissionType',
        loadChildren: () =>
          import('./transmissionType/transmissionType.module').then(
            (m) => m.TransmissionTypeModule
          )
      },

      {
        path: 'customerAlertMessage',
        loadChildren: () =>
          import('./customerAlertMessage/customerAlertMessage.module').then(
            (m) => m.CustomerAlertMessageModule
          )
      },

      {
        path: 'dutyInterstateTax',
        loadChildren: () =>
          import('./dutyInterstateTax/dutyInterstateTax.module').then(
            (m) => m.DutyInterstateTaxModule
          )
      },

      {
        path: 'dutyInterstateTaxApproval',
        loadChildren: () =>
          import('./dutyInterstateTaxApproval/dutyInterstateTaxApproval.module').then(
            (m) => m.DutyInterstateTaxApprovalModule
          )
      },

      {
        path: 'customerInfo',
        loadChildren: () =>
          import('./customerInfo/customerInfo.module').then(
            (m) => m.CustomerInfoModule
          )
      },
      {
        path: 'reservationClosingDetails',
        loadChildren: () =>
          import('./reservationClosingDetails/reservationClosingDetails.module').then(
            (m) => m.ReservationClosingDetailsModule
          )
      },

      {
        path: 'gstPercentage',
        loadChildren: () =>
          import('./gstPercentage/gstPercentage.module').then(
            (m) => m.GSTPercentageModule
          )
      },

      // {
      //   path: 'SingleDutySingleBillForOutstation',
      //   loadChildren: () =>
      //     import('./SingleDutySingleBillForOutstation/SingleDutySingleBillForOutstation.module').then(
      //       (m) => m.SingleDutySingleBillForOutstationModule
      //     )
      // },

      // {
      //   path: 'SingleDutySingleBillForLocal',
      //   loadChildren: () =>
      //     import('./SingleDutySingleBillForLocal/SingleDutySingleBillForLocal.module').then(
      //       (m) => m.SingleDutySingleBillForLocalModule
      //     )
      // },

  // {
  //       path: 'gstPercentage',
  //       loadChildren: () =>
  //         import('./gstPercentage/gstPercentage.module').then(
  //           (m) => m.GSTPercentageModule
  //         )
  //     },

  {
    path: 'tollParkingType',
    loadChildren: () =>
      import('./tollParkingType/tollParkingType.module').then(
        (m) => m.TollParkingTypeModule
      )
  },
  {
    path: 'interstateTaxEntry',
    loadChildren: () =>
      import('./interstateTaxEntry/interstateTaxEntry.module').then(
        (m) => m.InterstateTaxEntryModule
      )
  },

      {
        path: 'tripFeedBack',
        loadChildren: () =>
          import('./tripFeedBack/tripFeedBack.module').then(
            (m) => m.TripFeedBackModule
          )
      },
      {
        path: 'otherDetails',
        loadChildren: () =>
          import('./otherDetails/otherDetails.module').then(
            (m) => m.OtherDetailsModule
          )
      },

      {
        path: 'passwordReset',
        loadChildren: () =>
          import('./PasswordReset/PasswordReset.module').then(
            (m) => m.PasswordResetModule
          )
      },

      {
        path: 'reservationGroup',
        loadChildren: () =>
          import('./reservationGroup/reservationGroup.module').then(
            (m) => m.ReservationGroupModule
          )
      },

      {
        path: 'reservationGroupDetails',
        loadChildren: () =>
          import('./reservationGroupDetails/reservationGroupDetails.module').then(
            (m) => m.ReservationGroupDetailsModule
          )
      },
      
      {
        path: 'currentDuty',
        loadChildren: () =>
          import('./currentDuty/currentDuty.module').then(
            (m) => m.CurrentDutyModule
          )
      },
      {
        path: 'clossingScreen',
        loadChildren: () =>
          import('./clossingScreen/clossingScreen.module').then(
            (m) => m.ClossingScreenModule
          )
      },
      {
        path: 'reservationDetails',
        loadChildren: () =>
          import('./reservationDetails/reservationDetails.module').then(
            (m) => m.ReservationDetailsModule
          )
      },

      {
        path: 'passengerDetails',
        loadChildren: () =>
          import('./passengerDetails/passengerDetails.module').then(
            (m) => m.PassengerDetailsModule
          )
      },

      {
        path: 'customerSpecificDetails',
        loadChildren: () =>
          import('./customerSpecificDetails/customerSpecificDetails.module').then(
            (m) => m.CustomerSpecificDetailsModule
          )
      },

      {
        path: 'discountDetails',
        loadChildren: () =>
          import('./discountDetails/discountDetails.module').then(
            (m) => m.DiscountDetailsModule
          )
      },

      {
        path: 'monthlyBusinessReport',
        loadChildren: () =>
          import('./monthlyBusinessReport/monthlyBusinessReport.module').then(
            (m) => m.MonthlyBusinessReportModule
          )
      },

      {
        path: 'lumpsumRateDetails',
        loadChildren: () =>
          import('./lumpsumRateDetails/lumpsumRateDetails.module').then(
            (m) => m.LumpsumRateDetailsModule
          )
      },

      {
        path: 'settledRateDetails',
        loadChildren: () =>
          import('./settledRateDetails/settledRateDetails.module').then(
            (m) => m.SettledRateDetailsModule
          )
      },

      {
        path: 'billToOtherDetails',
        loadChildren: () =>
          import('./billToOtherDetails/billToOtherDetails.module').then(
            (m) => m.BillToOtherDetailsModule
          )
      },

      {
        path: 'kamCard',
        loadChildren: () =>
          import('./kamCard/kamCard.module').then(
            (m) => m.KamCardModule
          )
      },

      {
        path: 'supplierVerificationDocuments',
        loadChildren: () =>
          import('./supplierVerificationDocuments/supplierVerificationDocuments.module').then(
            (m) => m.SupplierVerificationDocumentsModule
          )
      },

      {
        path: 'dispatchFetchData',
        loadChildren: () =>
          import('./dispatchFetchData/dispatchFetchData.module').then(
            (m) => m.DispatchFetchDataModule
          )
      },

      {
        path: 'currencyExchangeRate',
        loadChildren: () =>
          import('./currencyExchangeRate/currencyExchangeRate.module').then(
            (m) => m.CurrencyExchangeRateModule
          )
      },
      
      {
        path: 'CarAndDriverSearch',
        loadChildren: () =>
          import('./CarAndDriverSearch/CarAndDriverSearch.module').then(
            (m) => m.CarAndDriverSearchModule
          )
      },
      
      {
        path: 'passengerhistory',
        loadChildren: () =>
          import('./PassengerHistory/PassengerHistory.module').then(
            (m) => m.PassengerHistoryModule
          )
      },
      {
        path: 'ExistingBids',
        loadChildren: () =>
          import('./ExistingBids/ExistingBids.module').then(
            (m) => m.ExistingBidsModule
          )
      },
      {
        path: 'AddCarAndDriver',
        loadChildren: () =>
          import('./AddCarAndDriver/AddCarAndDriver.module').then(
            (m) => m.AddCarAndDriverModule
          )
      },
      
      {
        path: 'imageUploader',
        loadChildren: () =>
          import('./imageUploader/imageUploader.module').then(
            (m) => m.ImageUploaderModule
          )
      },
      {
        path: 'bookingScreen',
        loadChildren: () =>
          import('./bookingScreen/bookingScreen.module').then(
            (m) => m.BookingScreenModule
          )
      },
      {
        path: 'tripDetails',
        loadChildren: () =>
          import('./tripDetails/tripDetails.module').then(
            (m) => m.TripDetailsModule
          )
      },

      {
        path: 'reservation',
        loadChildren: () =>
          import('./reservation/reservation.module').then(
            (m) => m.ReservationModule
          )
      },

      {
        path: 'reservationList',
        loadChildren: () =>
          import('./reservationList/reservationList.module').then(
            (m) => m.ReservationListModule
          )
      },
      
      {
        path: 'customerSpecificFields',
        loadChildren: () =>
          import('./customerSpecificFields/customerSpecificFields.module').then(
            (m) => m.CustomerSpecificFieldsModule
          )
      },
      {
        path: 'pickUpByExecutive',
        loadChildren: () =>
          import('./pickUpByExecutive/pickUpByExecutive.module').then(
            (m) => m.PickUpByExecutiveModule
          )
      },
      {
        path: 'dropOffByExecutive',
        loadChildren: () =>
          import('./dropOffByExecutive/dropOffByExecutive.module').then(
            (m) => m.DropOffByExecutiveModule
          )
      },

      {
        path: 'otherFields',
        loadChildren: () =>
          import('./otherFields/otherFields.module').then(
            (m) => m.OtherFieldsModule
          )
      },
      {
        path: 'reservationSource',
        loadChildren: () =>
          import('./reservationSource/reservationSource.module').then(
            (m) => m.ReservationSourceModule
          )
      },
      {
        path: 'controlPanelDesign',
        loadChildren: () =>
          import('./controlPanelDesign/controlPanelDesign.module').then(
            (m) => m.ControlPanelDesignModule
          )
      },

      {
        path: 'controlPanelTemp',
        loadChildren: () =>
          import('./controlPanelTemp/controlPanelTemp.module').then(
            (m) => m.ControlPanelTempModule
          )
      },

      {
        path: 'CarAndDriverAllotmentTemp',
        loadChildren: () =>
          import('./CarAndDriverAllotmentTemp/CarAndDriverAllotmentTemp.module').then(
            (m) => m.CarAndDriverAllotmentTempModule
          )
      },

      {
        path: 'addDiscount',
        loadChildren: () =>
          import('./addDiscount/addDiscount.module').then(
            (m) => m.AddDiscountModule
          )
      },
      {
        path: 'settledRates',
        loadChildren: () =>
          import('./settledRates/settledRates.module').then(
            (m) => m.SettledRatesModule
          )
      },
      {
        path: 'billingInstruction',
        loadChildren: () =>
          import('./billingInstruction/billingInstruction.module').then(
            (m) => m.BillingInstructionModule
          )
      },

      {
        path: 'billToOther',
        loadChildren: () =>
          import('./billToOther/billToOther.module').then(
            (m) => m.BillToOtherModule
          )
      },
      {
        path: 'customerReservationFields',
        loadChildren: () =>
          import('./customerReservationFields/customerReservationFields.module').then(
            (m) => m.CustomerReservationFieldsModule
          )
      },

      {
        path: 'customerReservationFieldValues',
        loadChildren: () =>
          import('./customerReservationFieldValues/customerReservationFieldValues.module').then(
            (m) => m.CustomerReservationFieldValuesModule
          )
      },
	  
      {
        path: 'viewKAM',
        loadChildren: () =>
          import('./viewKAM/viewKAM.module').then(
            (m) => m.ViewKAMModule
          )
      },
      {
        path: 'kamDetails',
        loadChildren: () =>
          import('./kamDetails/kamDetails.module').then(
            (m) => m.KamDetailsModule
          )
      },
      {
        path: 'additionalSMSEmailWhatsapp',
        loadChildren: () =>
          import('./additionalSMSEmailWhatsapp/additionalSMSEmailWhatsapp.module').then(
            (m) => m.AdditionalSMSEmailWhatsappModule
          )
      },
      {
        path: 'addPassengers',
        loadChildren: () =>
          import('./addPassengers/addPassengers.module').then(
            (m) => m.AddPassengersModule
          )
      },
      {
        path: 'addStop',
        loadChildren: () =>
          import('./addStop/addStop.module').then(
            (m) => m.AddStopModule
          )
      },

      {
        path: 'dailyReport',
        loadChildren: () =>
          import('./dailyReport/dailyReport.module').then(
            (m) => m.DailyReportModule
          )
      },
      {
        path: 'stopsPopup',
        loadChildren: () =>
          import('./stopsPopup/stopsPopup.module').then(
            (m) => m.StopsPopupModule
          )
      },
      {
        path: 'dropOffDetails',
        loadChildren: () =>
          import('./dropOffDetails/dropOffDetails.module').then(
            (m) => m.DropOffDetailsModule
          )
      },

      {
        path: 'additionalKmsDetails',
        loadChildren: () =>
          import('./additionalKmsDetails/additionalKmsDetails.module').then(
            (m) => m.AdditionalKmsDetailsModule
          )
      },
      {
        path: 'pickupDetails',
        loadChildren: () =>
          import('./pickupDetails/pickupDetails.module').then(
            (m) => m.PickupDetailsModule
          )
      },
      {
        path: 'specialInstruction',
        loadChildren: () =>
          import('./specialInstruction/specialInstruction.module').then(
            (m) => m.SpecialInstructionModule
          )
      },

      {
        path: 'stopDetails',
        loadChildren: () =>
          import('./stopDetails/stopDetails.module').then(
            (m) => m.StopDetailsModule
          )
      },

      {
        path: 'advance',
        loadChildren: () =>
          import('./advance/advance.module').then(
            (m) => m.AdvanceModule
          )
      },

      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then(
            (m) => m.ProfileModule
          )
      },

      {
        path: 'printdutyslip',
        loadChildren: () =>
          import('./PrintDutySlip/PrintDutySlip.module').then(
            (m) => m.PrintDutySlipModule
          )
      },

      {
        path: 'citybasedspots',
        loadChildren: () =>
          import('./CityBasedSpots/CityBasedSpots.module').then(
            (m) => m.CityBasedSpotsModule
          )
      },

      {
        path: 'tollParkingISTImages',
        loadChildren: () =>
          import('./TollParkingISTImages/TollParkingISTImages.module').then(
            (m) => m.TollParkingISTImagesModule
          )
      },

      {
        path: 'reservationStopDetails',
        loadChildren: () =>
          import('./reservationStopDetails/reservationStopDetails.module').then(
            (m) => m.ReservationStopDetailsModule
          )
      },

       {
        path: 'reservationStopDetails',
        loadChildren: () =>
          import('./reservationStopDetails/reservationStopDetails.module').then(
            (m) => m.ReservationStopDetailsModule
          )
      },
      
      {
        path: 'dutyDetails',
        loadChildren: () =>
          import('./dutyDetails/dutyDetails.module').then(
            (m) => m.DutyDetailsModule
          )
      },

      {
        path: 'sendEmsAndEmail',
        loadChildren: () =>
          import('./sendEmsAndEmail/sendEmsAndEmail.module').then(
            (m) => m.SendEmsAndEmailModule
          )
      },
      {
        path: 'tripFilter',
        loadChildren: () =>
          import('./tripFilter/tripFilter.module').then(
            (m) => m.TripFilterModule
          )
      },
      {
        path: 'CarAndDriverActions',
        loadChildren: () =>
          import('./CarAndDriverActions/CarAndDriverActions.module').then(
            (m) => m.CarAndDriverActionsModule
          )
      },
      {
        path: 'CarAndDriverAllotment',
        loadChildren: () =>
          import('./CarAndDriverAllotment/CarAndDriverAllotment.module').then(
            (m) => m.CarAndDriverAllotmentModule
          )
      },
      {
        path: 'AttachAnotherCar',
        loadChildren: () =>
          import('./AttachAnotherCar/AttachAnotherCar.module').then(
            (m) => m.AttachAnotherCarModule
          )
      },
      {
        path: 'AttachAnotherDriver',
        loadChildren: () =>
          import('./AttachAnotherDriver/AttachAnotherDriver.module').then(
            (m) => m.AttachAnotherDriverModule
          )
      },
      {
        path: 'DriverFeedbackInfo',
        loadChildren: () =>
          import('./DriverFeedbackInfo/DriverFeedbackInfo.module').then(
            (m) => m.DriverFeedbackInfoModule
          )
      },
      {
        path: 'bookerinfo',
        loadChildren: () =>
          import('./BookerInfo/BookerInfo.module').then(
            (m) => m.BookerInfoModule
          )
      },
      {
        path: 'vehiclecategoryinfo',
        loadChildren: () =>
          import('./VehicleCategoryInfo/VehicleCategoryInfo.module').then(
            (m) => m.VehicleCategoryInfoModule
          )
      },
      {
        path: 'vehicleinfo',
        loadChildren: () =>
          import('./VehicleInfo/VehicleInfo.module').then(
            (m) => m.VehicleInfoModule
          )
      },  
      {
        path: 'packageinfo',
        loadChildren: () =>
          import('./PackageInfo/PackageInfo.module').then(
            (m) => m.PackageInfoModule
          )
      },

      {
        path: 'passengerinfo',
        loadChildren: () =>
          import('./PassengerInfo/PassengerInfo.module').then(
            (m) => m.PassengerInfoModule
          )
      },

      {
        path: 'allotmentStatusDetails',
        loadChildren: () =>
          import('./AllotmentStatusDetails/AllotmentStatusDetails.module').then(
            (m) => m.AllotmentStatusDetailsModule
          )
      },

      {
        path: 'dutySlipQualityCheckDetails',
        loadChildren: () =>
          import('./DutySlipQualityCheckDetails/DutySlipQualityCheckDetails.module').then(
            (m) => m.DutySlipQualityCheckDetailsModule
          )
      },

      {
        path: 'garageOutDetails',
        loadChildren: () =>
          import('./GarageOutDetails/GarageOutDetails.module').then(
            (m) => m.GarageOutDetailsModule
          )
      },

      {
        path: 'reachedByExecutiveDetails',
        loadChildren: () =>
          import('./ReachedByExecutiveDetails/ReachedByExecutiveDetails.module').then(
            (m) => m.ReachedByExecutiveDetailsModule
          )
      },

      {
        path: 'pickUpDetailShow',
        loadChildren: () =>
          import('./pickUpDetailShow/pickUpDetailShow.module').then(
            (m) => m.PickUpDetailShowModule
          )
      },
    
      {
        path: 'locationInDetailShow',
        loadChildren: () =>
          import('./locationInDetailShow/locationInDetailShow.module').then(
            (m) => m.LocationInDetailShowShowModule
          )
      },
    
      {
        path: 'dropOffDetailShow',
        loadChildren: () =>
          import('./dropOffDetailShow/dropOffDetailShow.module').then(
            (m) => m.DropOffDetailShowModule
          )
      },
    
      {
        path: 'dutySlipQualityCheckedByExecutiveDetails',
        loadChildren: () =>
          import('./DutySlipQualityCheckedByExecutiveDetails/DutySlipQualityCheckedByExecutiveDetails.module').then(
            (m) => m.DutySlipQualityCheckedByExecutiveDetailsModule
          )
      },


      {
        path: 'stopDetailsShow',
        loadChildren: () =>
          import('./stopDetailsShow/stopDetailsShow.module').then(
            (m) => m.StopDetailsShowModule
          )
      },
      {
        path: 'specialinstructioninfo',
        loadChildren: () =>
          import('./SpecialInstructionInfo/SpecialInstructionInfo.module').then(
            (m) => m.SpecialInstructionInfoModule
          )
      },

      {
        path: 'timeandaddressinfo',
        loadChildren: () =>
          import('./TimeAndAddressInfo/TimeAndAddressInfo.module').then(
            (m) => m.TimeAndAddressInfoModule
          )
      },

      {
        path: 'stopdetailsinfo',
        loadChildren: () =>
          import('./StopDetailsInfo/StopDetailsInfo.module').then(
            (m) => m.StopDetailsInfoModule
          )
      },
      {
        path: 'stoponmapinfo',
        loadChildren: () =>
          import('./StopOnMapInfo/StopOnMapInfo.module').then(
            (m) => m.StopOnMapInfoModule
          )
      },
      {
        path: 'vendorassignment',
        loadChildren: () =>
          import('./VendorAssignment/VendorAssignment.module').then(
            (m) => m.VendorAssignmentModule
          )
      },

      {
        path: 'vendorinfo',
        loadChildren: () =>
          import('./VendorInfo/VendorInfo.module').then(
            (m) => m.VendorInfoModule
          )
      },

      {
        path: 'vehicleassignment',
        loadChildren: () =>
          import('./VehicleAssignment/VehicleAssignment.module').then(
            (m) => m.VehicleAssignmentModule
          )
      },
      {
        path: 'otherFilter',
        loadChildren: () =>
          import('./otherFilter/otherFilter.module').then(
            (m) => m.OtherFilterModule
          )
      },

      {
        path: 'customerPersonDrivingLicenseVerification',
        loadChildren: () =>
          import('./customerPersonDrivingLicenseVerification/customerPersonDrivingLicenseVerification.module').then(
            (m) => m.CustomerPersonDrivingLicenseVerificationModule
          )
      },
      {
        path: 'customerPersonDocument',
        loadChildren: () =>
          import('./customerPersonDocument/customerPersonDocument.module').then(
            (m) => m.CustomerPersonDocumentModule
          )
      },
      {
        path: 'customerPersonDocumentVerification',
        loadChildren: () =>
          import('./customerPersonDocumentVerification/customerPersonDocumentVerification.module').then(
            (m) => m.CustomerPersonDocumentVerificationModule
          )
      },
      {
        path: 'supplierVerificationStatusHistory',
        loadChildren: () =>
          import('./supplierVerificationStatusHistory/supplierVerificationStatusHistory.module').then(
            (m) => m.SupplierVerificationStatusHistoryModule
          )
      },
      {
        path: 'cityGroup',
        loadChildren: () =>
          import('./cityGroup/cityGroup.module').then(
            (m) => m.CityGroupModule
          )
      },
      {
        path: 'country',
        loadChildren: () =>
          import('./country/country.module').then(
            (m) => m.CountryModule
          )
      },

      {
        path: 'department',
        loadChildren: () =>
          import('./department/department.module').then(
            (m) => m.DepartmentModule
          )
      },
      {
        path: 'customerConfigurationMessaging',
        loadChildren: () =>
          import('./customerConfigurationMessaging/customerConfigurationMessaging.module').then(
            (m) => m.CustomerConfigurationMessagingModule
          )
      },
      {
        path: 'customerConfigurationInvoicing',
        loadChildren: () =>
          import('./customerConfigurationInvoicing/customerConfigurationInvoicing.module').then(
            (m) => m.CustomerConfigurationInvoicingModule
          )
      },
      {
        path: 'customerKeyAccountManager',
        loadChildren: () =>
          import('./customerKeyAccountManager/customerKeyAccountManager.module').then(
            (m) => m.CustomerKeyAccountManagerModule
          )
      },
      {
        path: 'customerBillingExecutive',
        loadChildren: () =>
          import('./customerBillingExecutive/customerBillingExecutive.module').then(
            (m) => m.CustomerBillingExecutiveModule
          )
      },
      {
        path: 'customerReservationExecutive',
        loadChildren: () =>
          import('./customerReservationExecutive/customerReservationExecutive.module').then(
            (m) => m.CustomerReservationExecutiveModule
          )
      },
      {
        path: 'customerSalesManager',
        loadChildren: () =>
          import('./customerSalesManager/customerSalesManager.module').then(
            (m) => m.CustomerSalesManagerModule
          )
      },
      {
        path: 'customerCollectionExecutive',
        loadChildren: () =>
          import('./customerCollectionExecutive/customerCollectionExecutive.module').then(
            (m) => m.CustomerCollectionExecutiveModule
          )
      },
      {
        path: 'customerCredit',
        loadChildren: () =>
          import('./customerCredit/customerCredit.module').then(
            (m) => m.CustomerCreditModule
          )
      },

      // {
      //   path: 'savedAddress',
      //   loadChildren: () =>
      //     import('./savedAddress/savedAddress.module').then(
      //       (m) => m.SavedAddressModule
      //     )
      // },
      {
        path: 'customerShort',
        loadChildren: () =>
          import('./customerShort/customerShort.module').then(
            (m) => m.CustomerShortModule
          )
      },

      {
        path: 'personShort',
        loadChildren: () =>
          import('./personShort/personShort.module').then(
            (m) => m.PersonShortModule
          )
      },

      {
        path: 'cdcLocalOnDemandRate',
        loadChildren: () =>
          import('./cdcLocalOnDemandRate/cdcLocalOnDemandRate.module').then(
            (m) => m.CDCLocalOnDemandRateModule
          )
      },

      {
        path: 'customerServiceExecutive',
        loadChildren: () =>
          import('./customerServiceExecutive/customerServiceExecutive.module').then(
            (m) => m.CustomerServiceExecutiveModule
          )
      },
      {
        path: 'customerPersonInstruction',
        loadChildren: () =>
          import('./customerPersonInstruction/customerPersonInstruction.module').then(
            (m) => m.CustomerPersonInstructionModule
          )
      },
      {
        path: 'stopReservation',
        loadChildren: () =>
          import('./stopReservation/stopReservation.module').then(
            (m) => m.StopReservationModule
          )
      },

      {
        path: 'cdcLongTermRentalRate',
        loadChildren: () =>
          import('./cdcLongTermRentalRate/cdcLongTermRentalRate.module').then(
            (m) => m.CDCLongTermRentalRateModule
          )
      },
      {
        path: 'cdcOutStationOneWayTripRate',
        loadChildren: () =>
          import('./cdcOutStationOneWayTripRate/cdcOutStationOneWayTripRate.module').then(
            (m) => m.CDCOutStationOneWayTripRateModule
          )
      },

      {
        path: 'customerContractMapping',
        loadChildren: () =>
          import('./customerContractMapping/customerContractMapping.module').then(
            (m) => m.CustomerContractMappingModule
          )
      },
      {
        path: 'country',
        loadChildren: () =>
          import('./country/country.module').then(
            (m) => m.CountryModule
          )
      },

      {
        path: 'supplier',
        loadChildren: () =>
          import('./supplier/supplier.module').then(
            (m) => m.SupplierModule
          )
      },

      {
        path: 'supplierActivationStatusHistory',
        loadChildren: () =>
          import('./supplierActivationStatusHistory/supplierActivationStatusHistory.module').then(
            (m) => m.SupplierActivationStatusHistoryModule
          )
      },

      {
        path: 'designation',
        loadChildren: () =>
          import('./designation/designation.module').then(
            (m) => m.DesignationModule
          )
      },

      {
        path: 'qualification',
        loadChildren: () =>
          import('./qualification/qualification.module').then(
            (m) => m.QualificationModule
          )
      },

      {
        path: 'inventoryBlock',
        loadChildren: () =>
          import('./inventoryBlock/inventoryBlock.module').then(
            (m) => m.InventoryBlockModule
          )
      },
      {
        path: 'inventoryFitness',
        loadChildren: () =>
          import('./inventoryFitness/inventoryFitness.module').then(
            (m) => m.InventoryFitnessModule
          )
      },

      {
        path: 'customerContract',
        loadChildren: () =>
          import('./customerContract/customerContract.module').then(
            (m) => m.CustomerContractModule
          )
      },

      {
        path: 'customerConfigurationBilling',
        loadChildren: () =>
          import('./customerConfigurationBilling/customerConfigurationBilling.module').then(
            (m) => m.CustomerConfigurationBillingModule
          )
      },

      {
        path: 'customerConfigurationReservation',
        loadChildren: () =>
          import('./customerConfigurationReservation/customerConfigurationReservation.module').then(
            (m) => m.CustomerConfigurationReservationModule
          )
      },

      {
        path: 'customerConfigurationSupplier',
        loadChildren: () =>
          import('./customerConfigurationSupplier/customerConfigurationSupplier.module').then(
            (m) => m.CustomerConfigurationSupplierModule
          )
      },

      {
        path: 'customerAddress',
        loadChildren: () =>
          import('./customerAddress/customerAddress.module').then(
            (m) => m.CustomerAddressModule
          )
      },

      {
        path: 'customerBlocking',
        loadChildren: () =>
          import('./customerBlocking/customerBlocking.module').then(
            (m) => m.CustomerBlockingModule
          )
      },

      {
        path: 'inventoryPUC',
        loadChildren: () =>
          import('./inventoryPUC/inventoryPUC.module').then(
            (m) => m.InventoryPUCModule
          )
      },

      {
        path: 'vehicleCategory',
        loadChildren: () =>
          import('./vehicleCategory/vehicleCategory.module').then(
            (m) => m.VehicleCategoryModule
          )
      },
      {
        path: 'spotInCity',
        loadChildren: () =>
          import('./spotInCity/spotInCity.module').then(
            (m) => m.SpotInCityModule
          )
      },
      {
        path: 'serviceType',
        loadChildren: () =>
          import('./serviceType/serviceType.module').then(
            (m) => m.ServiceTypeModule
          )
      },
      {
        path: 'state',
        loadChildren: () =>
          import('./state/state.module').then(
            (m) => m.StateModule
          )
      },

      {
        path: 'test',
        loadChildren: () =>
          import('./test/test.module').then(
            (m) => m.TestModule
          )
      },

      {
        path: 'uom',
        loadChildren: () =>
          import('./uom/uom.module').then(
            (m) => m.UomModule
          )
      },

      {
        path: 'package',
        loadChildren: () =>
          import('./package/package.module').then(
            (m) => m.PackageModule
          )
      },

      {
        path: 'packageType',
        loadChildren: () =>
          import('./packageType/packageType.module').then(
            (m) => m.PackageTypeModule
          )
      },

      {
        path: 'customerPerson',
        loadChildren: () =>
          import('./customerPerson/customerPerson.module').then(
            (m) => m.CustomerPersonModule
          )
      },

      {
        path: 'reservationAlert',
        loadChildren: () =>
          import('./reservationAlert/reservationAlert.module').then(
            (m) => m.ReservationAlertModule
          )
      },

      {
        path: 'customerPersonDriverRestriction',
        loadChildren: () =>
          import('./customerPersonDriverRestriction/customerPersonDriverRestriction.module').then(
            (m) => m.CustomerPersonDriverRestrictionModule
          )
      },

      {
        path: 'customerFuelSurcharge',
        loadChildren: () =>
          import('./customerFuelSurcharge/customerFuelSurcharge.module').then(
            (m) => m.CustomerFuelSurchargeModule
          )
      },

      {
        path: 'customerPersonTempVIP',
        loadChildren: () =>
          import('./customerPersonTempVIP/customerPersonTempVIP.module').then(
            (m) => m.CustomerPersonTempVIPModule
          )
      },

 {
        path: 'sdUnLimitedRate',
        loadChildren: () =>
          import('./sdUnLimitedRate/sdUnLimitedRate.module').then(
            (m) => m.SDUnLimitedRateModule
          )
      },
      // {
      //   path: 'self-drive-unlimited-rates',
      //   loadChildren: () =>
      //     import('./self-drive-unlimited-rates/self-drive-unlimited-rates.module').then(
      //       (m) => m.SelfDriveUnlimitedRatesModule
      //     )
      // },

      {
        path: 'page',
        loadChildren: () =>
          import('./page/page.module').then((m) => m.PageModule)
      },

      {
        path: 'role',
        loadChildren: () =>
          import('./role/role.module').then((m) => m.RoleModule)
      },

      {
        path: 'rolePageMapping/:RoleID/:Role',
        loadChildren: () =>
          import('./rolePageMapping/rolePageMapping.module').then(
            (m) => m.RolePageMappingModule
          )
      },

      {
        path: 'customerPersonPreferedDriver',
        loadChildren: () =>
          import('./customerPersonPreferedDriver/customerPersonPreferedDriver.module').then(
            (m) => m.CustomerPersonPreferedDriverModule
          )
      },

      {
        path: 'customerPersonAlertMessages',
        loadChildren: () =>
          import('./customerPersonAlertMessages/customerPersonAlertMessages.module').then(
            (m) => m.CustomerPersonAlertMessagesModule
          )
      },

      {
        path: 'driverGrade',
        loadChildren: () =>
          import('./driverGrade/driverGrade.module').then(
            (m) => m.DriverGradeModule
          )
      },

      {
        path: 'driverReward',
        loadChildren: () =>
          import('./driverReward/driverReward.module').then(
            (m) => m.DriverRewardModule
          )
      },

      {
        path: 'customerContractCDCLocalRate',
        loadChildren: () =>
          import('./customerContractCDCLocalRate/customerContractCDCLocalRate.module').then(
            (m) => m.CustomerContractCDCLocalRateModule
          )
      },

      {
        path: 'cdcLocalLumpsumRate',
        loadChildren: () =>
          import('./cdcLocalLumpsumRate/cdcLocalLumpsumRate.module').then(
            (m) => m.CDCLocalLumpsumRateModule
          )
      },

      {
        path: 'cdcOutStationRoundTripRate',
        loadChildren: () =>
          import('./cdcOutStationRoundTripRate/cdcOutStationRoundTripRate.module').then(
            (m) => m.CDCOutStationRoundTripRateModule
          )
      },

      {
        path: 'cdcOutStationLumpsumRate',
        loadChildren: () =>
          import('./cdcOutStationLumpsumRate/cdcOutStationLumpsumRate.module').then(
            (m) => m.CDCOutStationLumpsumRateModule
          )
      },

      {
        path: 'customerDiscount',
        loadChildren: () =>
          import('./customerDiscount/customerDiscount.module').then(
            (m) => m.CustomerDiscountModule
          )
      },

      {
        path: 'acrisCode',
        loadChildren: () =>
          import('./acrisCode/acrisCode.module').then(
            (m) => m.AcrisCodeModule
          )
      },

      {
        path: 'salutation',
        loadChildren: () =>
          import('./salutation/salutation.module').then(
            (m) => m.SalutationModule
          )
      },

      {
        path: 'additionalService',
        loadChildren: () =>
          import('./additionalService/additionalService.module').then(
            (m) => m.AdditionalServiceModule
          )
      },

      {
        path: 'additionalServiceRate',
        loadChildren: () =>
          import('./additionalServiceRate/additionalServiceRate.module').then(
            (m) => m.AdditionalServiceRateModule
          )
      },

      {
        path: 'bank',
        loadChildren: () =>
          import('./bank/bank.module').then(
            (m) => m.BankModule
          )
      },

      {
        path: 'card',
        loadChildren: () =>
          import('./card/card.module').then(
            (m) => m.CardModule
          )
      },

      {
        path: 'customer',
        loadChildren: () =>
          import('./customer/customer.module').then(
            (m) => m.CustomerModule
          )
      },

      {
        path: 'customerContactPerson',
        loadChildren: () =>
          import('./customerContactPerson/customerContactPerson.module').then(
            (m) => m.CustomerContactPersonModule
          )
      },

      {
        path: 'customerContractCityTiers',
        loadChildren: () =>
          import('./customerContractCityTiers/customerContractCityTiers.module').then(
            (m) => m.CustomerContractCityTiersModule
          )
      },

      {
        path: 'customerContractCityTiersCityMapping',
        loadChildren: () =>
          import('./customerContractCityTiersCityMapping/customerContractCityTiersCityMapping.module').then(
            (m) => m.CustomerContractCityTiersCityMappingModule
          )
      },

      {
        path: 'customerDepartment',
        loadChildren: () =>
          import('./customerDepartment/customerDepartment.module').then(
            (m) => m.CustomerDepartmentModule
          )
      },

      {
        path: 'cardType',
        loadChildren: () =>
          import('./cardType/cardType.module').then(
            (m) => m.CardTypeModule
          )
      },

      {
        path: 'color',
        loadChildren: () =>
          import('./color/color.module').then(
            (m) => m.ColorModule
          )
      },

      {
        path: 'cityTier',
        loadChildren: () =>
          import('./cityTier/cityTier.module').then(
            (m) => m.CityTierModule
          )
      },

      {
        path: 'customerConfigurationSEZ',
        loadChildren: () =>
          import('./customerConfigurationSEZ/customerConfigurationSEZ.module').then(
            (m) => m.CustomerConfigurationSEZModule
          )
      },

      {
        path: 'customerCarAndDriverDetailsSMSEMail',
        loadChildren: () =>
          import('./customerCarAndDriverDetailsSMSEMail/customerCarAndDriverDetailsSMSEMail.module').then(
            (m) => m.CustomerCarAndDriverDetailsSMSEMailModule
          )
      },
      {
        path: 'customerGroupSBTDomain',
        loadChildren: () =>
          import('./customerGroupSBTDomain/customerGroupSBTDomain.module').then(
            (m) => m.CustomerGroupSBTDomainModule
          )
      },
      {
        path: 'customerReservationAlert',
        loadChildren: () =>
          import('./customerReservationAlert/customerReservationAlert.module').then(
            (m) => m.CustomerReservationAlertModule
          )
      },

      {
        path: 'customerDesignation',
        loadChildren: () =>
          import('./customerDesignation/customerDesignation.module').then(
            (m) => m.CustomerDesignationModule
          )
      },
      {
        path: 'customerCategoryMapping',
        loadChildren: () =>
          import('./customerCategoryMapping/customerCategoryMapping.module').then(
            (m) => m.CustomerCategoryMappingModule
          )
      },

      {
        path: 'driverDocument',
        loadChildren: () =>
          import('./driverDocument/driverDocument.module').then(
            (m) => m.DriverDocumentModule
          )
      },

      {
        path: 'driverDocumentVerification',
        loadChildren: () =>
          import('./driverDocumentVerification/driverDocumentVerification.module').then(
            (m) => m.DriverDocumentVerificationModule
          )
      },

      {
        path: 'fuelType',
        loadChildren: () =>
          import('./fuelType/fuelType.module').then(
            (m) => m.FuelTypeModule
          )
      },

      {
        path: 'supplierRateCardSupplierMapping',
        loadChildren: () =>
          import('./supplierRateCardSupplierMapping/supplierRateCardSupplierMapping.module').then(
            (m) => m.SupplierRateCardSupplierMappingModule
          )
      },

      {
        path: 'supplierContractCDCOutStationRoundTrip',
        loadChildren: () =>
          import('./supplierContractCDCOutStationRoundTrip/supplierContractCDCOutStationRoundTrip.module').then(
            (m) => m.SupplierContractCDCOutStationRoundTripModule
          )
      },

      {
        path: 'supplierContractCDCLocalLumpsum',
        loadChildren: () =>
          import('./supplierContractCDCLocalLumpsum/supplierContractCDCLocalLumpsum.module').then(
            (m) => m.SupplierContractCDCLocalLumpsumModule
          )
      },

      {
        path: 'supplierContractSDCSelfDriveLimited',
        loadChildren: () =>
          import('./supplierContractSDCSelfDriveLimited/supplierContractSDCSelfDriveLimited.module').then(
            (m) => m.SupplierContractSDCSelfDriveLimitedModule
          )
      },

      {
        path: 'supplierContractSDCSelfDriveUnLimited',
        loadChildren: () =>
          import('./supplierContractSDCSelfDriveUnLimited/supplierContractSDCSelfDriveUnLimited.module').then(
            (m) => m.SupplierContractSDCSelfDriveUnLimitedModule
          )
      },

      {
        path: 'cardProcessingCharge',
        loadChildren: () =>
          import('./cardProcessingCharge/cardProcessingCharge.module').then(
            (m) => m.CardProcessingChargeModule
          )
      },
      {
        path: 'customerContractCarCategory',
        loadChildren: () =>
          import('./customerContractCarCategory/customerContractCarCategory.module').then(
            (m) => m.CustomerContractCarCategoryModule
          )
      },

      {
        path: 'sdlimitedRate',
        loadChildren: () =>
          import('./sdlimitedRate/sdlimitedRate.module').then(
            (m) => m.SDLimitedRateModule
          )
      },

      {
        path: 'cdcLocalTransferRate',
        loadChildren: () =>
          import('./cdcLocalTransferRate/cdcLocalTransferRate.module').then(
            (m) => m.CDCLocalTransferRateModule
          )
      },

      {
        path: 'customerContractCarCategoriesCarMapping',
        loadChildren: () =>
          import('./customerContractCarCategoriesCarMapping/customerContractCarCategoriesCarMapping.module').then(
            (m) => m.CustomerContractCarCategoriesCarMappingModule
          )
      },

      {
        path: 'lut',
        loadChildren: () =>
          import('./lut/lut.module').then(
            (m) => m.LutModule
          )
      },
      {
        path: 'locationGroup',
        loadChildren: () =>
          import('./locationGroup/locationGroup.module').then(
            (m) => m.LocationGroupModule
          )
      },

      {
        path: 'messageType',
        loadChildren: () =>
          import('./messageType/messageType.module').then(
            (m) => m.MessageTypeModule
          )
      },

      {
        path: 'bankBranch',
        loadChildren: () =>
          import('./bankBranch/bankBranch.module').then(
            (m) => m.BankBranchModule
          )
      },

      {
        path: 'paymentNetwork',
        loadChildren: () =>
          import('./paymentNetwork/paymentNetwork.module').then(
            (m) => m.PaymentNetworkModule
          )
      },

      {
        path: 'supplierCityMapping',
        loadChildren: () =>
          import('./supplierCityMapping/supplierCityMapping.module').then(
            (m) => m.SupplierCityMappingModule
          )
      },

      {
        path: 'supplierContractVehiclePercentage',
        loadChildren: () =>
          import('./supplierContractVehiclePercentage/supplierContractVehiclePercentage.module').then(
            (m) => m.SupplierContractVehiclePercentageModule
          )
      },
      {
        path: 'supplierContractCustomerVehiclePercentage',
        loadChildren: () =>
          import('./supplierContractCustomerVehiclePercentage/supplierContractCustomerVehiclePercentage.module').then(
            (m) => m.SupplierContractCustomerVehiclePercentageModule
          )
      },
      {
        path: 'supplierRateCard',
        loadChildren: () =>
          import('./supplierRateCard/supplierRateCard.module').then(
            (m) => m.SupplierRateCardModule
          )
      },
      {
        path: 'supplierContractCustomerPackageTypePercentage',
        loadChildren: () =>
          import('./supplierContractCustomerPackageTypePercentage/supplierContractCustomerPackageTypePercentage.module').then(
            (m) => m.SupplierContractCustomerPackageTypePercentageModule
          )
      },
      {
        path: 'supplierContractPackageTypePercentage',
        loadChildren: () =>
          import('./supplierContractPackageTypePercentage/supplierContractPackageTypePercentage.module').then(
            (m) => m.SupplierContractPackageTypePercentageModule
          )
      },

      {
        path: 'supplierContractCustomerCityPercentage',
        loadChildren: () =>
          import('./supplierContractCustomerCityPercentage/supplierContractCustomerCityPercentage.module').then(
            (m) => m.SupplierContractCustomerCityPercentageModule
          )
      },

      {
        path: 'paymentCycle',
        loadChildren: () =>
          import('./paymentCycle/paymentCycle.module').then(
            (m) => m.PaymentCycleModule
          )
      },

      {
        path: 'modeOfPayment',
        loadChildren: () =>
          import('./modeOfPayment/modeOfPayment.module').then(
            (m) => m.ModeOfPaymentModule
          )
      },

      {
        path: 'supplierContractPercentage',
        loadChildren: () =>
          import('./supplierContractPercentage/supplierContractPercentage.module').then(
            (m) => m.SupplierContractPercentageModule
          )
      },
      {
        path: 'supplierContractCityPercentage',
        loadChildren: () =>
          import('./supplierContractCityPercentage/supplierContractCityPercentage.module').then(
            (m) => m.SupplierContractCityPercentageModule
          )
      },

      {
        path: 'supplierContract',
        loadChildren: () =>
          import('./supplierContract/supplierContract.module').then(
            (m) => m.SupplierContractModule
          )
      },

      {
        path: 'reservationMessaging',
        loadChildren: () =>
          import('./reservationMessaging/reservationMessaging.module').then(
            (m) => m.ReservationMessagingModule
          )
      },

      {
        path: 'supplierCustomerFixedForAllPercentage',
        loadChildren: () =>
          import('./supplierCustomerFixedForAllPercentage/supplierCustomerFixedForAllPercentage.module').then(
            (m) => m.SupplierCustomerFixedForAllPercentageModule
          )
      },

      {
        path: 'dutyTollParkingEntry',
        loadChildren: () =>
          import('./dutyTollParkingEntry/dutyTollParkingEntry.module').then(
            (m) => m.DutyTollParkingEntryModule
          )
      },

      {
        path: 'currency',
        loadChildren: () =>
          import('./currency/currency.module').then(
            (m) => m.CurrencyModule
          )
      },

      {
        path: 'city',
        loadChildren: () =>
          import('./city/city.module').then(
            (m) => m.CityModule
          )
      },

      {
        path: 'customerPersonAddress',
        loadChildren: () =>
          import('./customerPersonAddress/customerPersonAddress.module').then(
            (m) => m.CustomerPersonAddressModule
          )
      },

      {
        path: 'customerPersonDrivingLicense',
        loadChildren: () =>
          import('./customerPersonDrivingLicense/customerPersonDrivingLicense.module').then(
            (m) => m.CustomerPersonDrivingLicenseModule
          )
      },

      {
        path: 'driverDrivingLicense',
        loadChildren: () =>
          import('./driverDrivingLicense/driverDrivingLicense.module').then(
            (m) => m.DriverDrivingLicenseModule
          )
      },

      {
        path: 'driverDrivingLicenseVerification',
        loadChildren: () =>
          import('./driverDrivingLicenseVerification/driverDrivingLicenseVerification.module').then(
            (m) => m.DriverDrivingLicenseVerificationModule
          )
      },

      {
        path: 'driver',
        loadChildren: () =>
          import('./driver/driver.module').then(
            (m) => m.DriverModule
          )
      },

      {
        path: 'driverInventoryAssociation',
        loadChildren: () =>
          import('./driverInventoryAssociation/driverInventoryAssociation.module').then(
            (m) => m.DriverInventoryAssociationModule
          )
      },

      {
        path: 'dutySlipQualityCheck',
        loadChildren: () =>
          import('./dutySlipQualityCheck/dutySlipQualityCheck.module').then(
            (m) => m.DutySlipQualityCheckModule
          )
      },

      {
        path: 'fetchDataRBE',
        loadChildren: () =>
          import('./fetchDataRBE/fetchDataRBE.module').then(
            (m) => m.FetchDataRBEModule
          )
      },

      {
        path: 'fetchDataFromApp',
        loadChildren: () =>
          import('./fetchDataFromApp/fetchDataFromApp.module').then(
            (m) => m.FetchDataFromAppModule
          )
      },
      {
        path: 'dutySlipQualityCheckedByExecutive',
        loadChildren: () =>
          import('./dutySlipQualityCheckedByExecutive/dutySlipQualityCheckedByExecutive.module').then(
            (m) => m.DutySlipQualityCheckedByExecutiveModule
          )
      },

      {
        path: 'dispatchByExecutive',
        loadChildren: () =>
          import('./dispatchByExecutive/dispatchByExecutive.module').then(
            (m) => m.DispatchByExecutiveModule
          )
      },

      {
        path: 'reachedByExecutive',
        loadChildren: () =>
          import('./reachedByExecutive/reachedByExecutive.module').then(
            (m) => m.ReachedByExecutiveModule
          )
      },

      {
        path: 'vehicle',
        loadChildren: () =>
          import('./vehicle/vehicle.module').then(
            (m) => m.VehicleModule
          )
      },

      {
        path: 'geoPointType',
        loadChildren: () =>
          import('./geoPointType/geoPointType.module').then(
            (m) => m.GeoPointTypeModule
          )
      },
      {
        path: 'document',
        loadChildren: () =>
          import('./document/document.module').then(
            (m) => m.DocumentModule
          )
      },

      {
        path: 'supplierRequiredDocument',
        loadChildren: () =>
          import('./supplierRequiredDocument/supplierRequiredDocument.module').then(
            (m) => m.SupplierRequiredDocumentModule
          )
      },

      {
        path: 'bankChargeConfig',
        loadChildren: () =>
          import('./bankChargeConfig/bankChargeConfig.module').then(
            (m) => m.BankChargeConfigModule
          )
      },

      {
        path: 'vehicleManufacturer',
        loadChildren: () =>
          import('./vehicleManufacturer/vehicleManufacturer.module').then(
            (m) => m.VehicleManufacturerModule
          )
      },

      {
        path: 'organizationalEntity',
        loadChildren: () =>
          import('./organizationalEntity/organizationalEntity.module').then(
            (m) => m.OrganizationalEntityModule
          )
      },
      {
        path: 'supplierContractVehiclePercentage',
        loadChildren: () =>
          import('./supplierContractVehiclePercentage/supplierContractVehiclePercentage.module').then(
            (m) => m.SupplierContractVehiclePercentageModule
          )
      },
      {
        path: 'supplierContractCustomerVehiclePercentage',
        loadChildren: () =>
          import('./supplierContractCustomerVehiclePercentage/supplierContractCustomerVehiclePercentage.module').then(
            (m) => m.SupplierContractCustomerVehiclePercentageModule
          )
      },
      {
        path: 'supplierRateCard',
        loadChildren: () =>
          import('./supplierRateCard/supplierRateCard.module').then(
            (m) => m.SupplierRateCardModule
          )
      },
      {
        path: 'supplierContractCustomerPackageTypePercentage',
        loadChildren: () =>
          import('./supplierContractCustomerPackageTypePercentage/supplierContractCustomerPackageTypePercentage.module').then(
            (m) => m.SupplierContractCustomerPackageTypePercentageModule
          )
      },
      {
        path: 'supplierContractPackageTypePercentage',
        loadChildren: () =>
          import('./supplierContractPackageTypePercentage/supplierContractPackageTypePercentage.module').then(
            (m) => m.SupplierContractPackageTypePercentageModule
          )
      },

      {
        path: 'inventoryInsurance',
        loadChildren: () =>
          import('./inventoryInsurance/inventoryInsurance.module').then(
            (m) => m.InventoryInsuranceModule
          )
      },

      {
        path: 'inventoryPermit',
        loadChildren: () =>
          import('./inventoryPermit/inventoryPermit.module').then(
            (m) => m.InventoryPermitModule
          )
      },
      {
        path: 'inventoryTarget',
        loadChildren: () =>
          import('./inventoryTarget/inventoryTarget.module').then(
            (m) => m.InventoryTargetModule
          )
      },
      {
        path: 'inventoryStatusHistory',
        loadChildren: () =>
          import('./inventoryStatusHistory/inventoryStatusHistory.module').then(
            (m) => m.InventoryStatusHistoryModule
          )
      },

      {
        path: 'customerGroup',
        loadChildren: () =>
          import('./customerGroup/customerGroup.module').then(
            (m) => m.CustomerGroupModule
          )
      },

      {
        path: 'customerCategory',
        loadChildren: () =>
          import('./customerCategory/customerCategory.module').then(
            (m) => m.CustomerCategoryModule
          )
      },

      {
        path: 'customerType',
        loadChildren: () =>
          import('./customerType/customerType.module').then(
            (m) => m.CustomerTypeModule
          )
      },

      {
        path: 'vehicleCategoryTarget',
        loadChildren: () =>
          import('./vehicleCategoryTarget/vehicleCategoryTarget.module').then(
            (m) => m.VehicleCategoryTargetModule
          )
      },

      {
        path: 'vehicleInterStateTax',
        loadChildren: () =>
          import('./vehicleInterStateTax/vehicleInterStateTax.module').then(
            (m) => m.VehicleInterStateTaxModule
          )
      },

      {
        path: 'inventory',
        loadChildren: () =>
          import('./inventory/inventory.module').then(
            (m) => m.InventoryModule
          )
      },

      {
        path: 'supplierContractCDCOutStationLumpsumTrip',
        loadChildren: () =>
          import('./supplierContractCDCOutStationLumpsumTrip/supplierContractCDCOutStationLumpsumTrip.module').then(
            (m) => m.SupplierContractCDCOutStationLumpsumTripModule
          )
      },

      {
        path: 'supplierContractCDCOutStationOnewayTrip',
        loadChildren: () =>
          import('./supplierContractCDCOutStationOnewayTrip/supplierContractCDCOutStationOnewayTrip.module').then(
            (m) => m.SupplierContractCDCOutStationOnewayTripModule
          )
      },

      {
        path: 'supplierContractCDCLongTermRental',
        loadChildren: () =>
          import('./supplierContractCDCLongTermRental/supplierContractCDCLongTermRental.module').then(
            (m) => m.SupplierContractCDCLongTermRentalModule
          )
      },

      {
        path: 'supplierContractCDCLocalOnDemand',
        loadChildren: () =>
          import('./supplierContractCDCLocalOnDemand/supplierContractCDCLocalOnDemand.module').then(
            (m) => m.SupplierContractCDCLocalOnDemandModule
          )
      },

      {
        path: 'supplierContractCDCLocalTransfer',
        loadChildren: () =>
          import('./supplierContractCDCLocalTransfer/supplierContractCDCLocalTransfer.module').then(
            (m) => m.SupplierContractCDCLocalTransferModule
          )
      },

      {
        path: 'supplierContractCDCLocal',
        loadChildren: () =>
          import('./supplierContractCDCLocal/supplierContractCDCLocal.module').then(
            (m) => m.SupplierContractCDCLocalModule
          )
      },

      {
        path: 'supplierContractSDCSelfDriveHourlyLimited',
        loadChildren: () =>
          import('./supplierContractSDCSelfDriveHourlyLimited/supplierContractSDCSelfDriveHourlyLimited.module').then(
            (m) => m.SupplierContractSDCSelfDriveHourlyLimitedModule
          )
      },

      {
        path: 'supplierContractSDCSelfDriveHourlyUnlimited',
        loadChildren: () =>
          import('./supplierContractSDCSelfDriveHourlyUnlimited/supplierContractSDCSelfDriveHourlyUnlimited.module').then(
            (m) => m.SupplierContractSDCSelfDriveHourlyUnlimitedModule
          )
      },

      {
        path: 'organizationalEntityMessage',
        loadChildren: () =>
          import('./organizationalEntityMessage/organizationalEntityMessage.module').then(
            (m) => m.OrganizationalEntityMessageModule
          )
      },

      {
        path: 'organizationalEntityStakeHolders',
        loadChildren: () =>
          import('./organizationalEntityStakeHolders/organizationalEntityStakeHolders.module').then(
            (m) => m.OrganizationalEntityStakeHoldersModule
          )
      },

      {
        path: 'currentDesgination',
        loadChildren: () =>
          import('./currentDesgination/currentDesgination.module').then(
            (m) => m.CurrentDesginationModule
          )
      },

      {
        path: 'employee',
        loadChildren: () =>
          import('./employee/employee.module').then(
            (m) => m.EmployeeModule
          )
      },
      {
        path: 'unlockEmployee',
        loadChildren: () =>
          import('./unlockEmployee/unlockEmployee.module').then(
            (m) => m.UnlockEmployeeModule
          )
      },

      {
        path: 'expense',
        loadChildren: () =>
          import('./expense/expense.module').then(
            (m) => m.ExpenseModule
          )
      },

      {
        path: 'dutyExpense',
        loadChildren: () =>
          import('./dutyExpense/dutyExpense.module').then(
            (m) => m.DutyExpenseModule
          )
      },

      {
        path: 'sac',
        loadChildren: () =>
          import('./sac/sac.module').then(
            (m) => m.SACModule
          )
      },

      {
        path: 'validateOTP',
        loadChildren: () =>
          import('./validateOTP/validateOTP.module').then(
            (m) => m.ValidateOTPModule
          )
      },
      {
        path: 'password',
        loadChildren: () =>
          import('./password/password.module').then(
            (m) => m.PasswordModule
          )
      },

      {
        path: 'singleDutySingleBill',
        loadChildren: () =>
          import('./singleDutySingleBill/singleDutySingleBill.module').then(
            (m) => m.SingleDutySingleBillModule
          )
      },

      {
        path: 'dutySAC',
        loadChildren: () =>
          import('./dutySAC/dutySAC.module').then(
            (m) => m.DutySACModule
          )
      },
      
      {
        path: 'dutyGSTPercentage',
        loadChildren: () =>
          import('./dutyGSTPercentage/dutyGSTPercentage.module').then(
            (m) => m.DutyGSTPercentageModule
          )
      },

    ]
  },
 
  {
    path: '',
    component: NoSidebarLayoutComponent,
    children: [
      {
        path: 'SingleDutySingleBillForLocal',
        loadChildren: () =>
          import('./SingleDutySingleBillForLocal/SingleDutySingleBillForLocal.module').then(
            (m) => m.SingleDutySingleBillForLocalModule
          )
      },

      {
        path: 'SingleDutySingleBillForOutstation',
        loadChildren: () =>
          import('./SingleDutySingleBillForOutstation/SingleDutySingleBillForOutstation.module').then(
            (m) => m.SingleDutySingleBillForOutstationModule
          )
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
